var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.isShowLoading}},[_c('validation-observer',{ref:"validateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveData($event)}}},[_c('b-card',{attrs:{"title":_vm.$t('user.personalInformation')}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.idCard') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.idCard'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.userIdCard),callback:function ($$v) {_vm.userIdCard=$$v},expression:"userIdCard"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.prefix') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.prefix'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"prefixName","options":_vm.optionPrefixes,"reduce":function (option) { return option.prefixId; }},model:{value:(_vm.prefixId),callback:function ($$v) {_vm.prefixId=$$v},expression:"prefixId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.firstName') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.firstName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.userFirstName),callback:function ($$v) {_vm.userFirstName=$$v},expression:"userFirstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.lastName') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.lastName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.userLastName),callback:function ($$v) {_vm.userLastName=$$v},expression:"userLastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.position') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.position'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"positionName","options":_vm.optionPositions,"reduce":function (option) { return option.positionId; }},model:{value:(_vm.positionId),callback:function ($$v) {_vm.positionId=$$v},expression:"positionId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.tel') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.tel'),"rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.userTel),callback:function ($$v) {_vm.userTel=$$v},expression:"userTel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(false)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.bloodType')}},[_c('v-select',{attrs:{"label":"bloodName","options":_vm.optionBloodTypes,"reduce":function (option) { return option.bloodId; }},model:{value:(_vm.userBloodType),callback:function ($$v) {_vm.userBloodType=$$v},expression:"userBloodType"}})],1)],1):_vm._e(),(false)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.congenitalDisease')}},[_c('b-form-input',{model:{value:(_vm.userCongenitalDisease),callback:function ($$v) {_vm.userCongenitalDisease=$$v},expression:"userCongenitalDisease"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.imageProfile') + _vm.showAsterisk}},[(_vm.userImagePath)?_c('thumbnail-file',{attrs:{"src":_vm.userImagePath}}):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('user.imageProfile'),"rules":_vm.id ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"accept":".jpg, .png","state":errors.length > 0 ? false : null},model:{value:(_vm.userImage),callback:function ($$v) {_vm.userImage=$$v},expression:"userImage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6","align-self":"end"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.email') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.email'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-card',{attrs:{"title":_vm.$t('user.emergencyContact')}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.emergencyName') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.emergencyName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.userEmergencyName),callback:function ($$v) {_vm.userEmergencyName=$$v},expression:"userEmergencyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.emergencyTel') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.emergencyTel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.userEmergencyTel),callback:function ($$v) {_vm.userEmergencyTel=$$v},expression:"userEmergencyTel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-card',{attrs:{"title":_vm.$t('user.attachments')}},[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary"},on:{"click":_vm.addRow}},[_vm._v(_vm._s(_vm.$t('user.btnAddAttachment')))])],1),_vm._l((_vm.userAttachmentLists),function(row,key){return _c('b-row',{key:key,staticClass:"attachment-row",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"md":"6","order":"2","order-md":"1"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.attachmentType') + '*',"vid":'attachmentType' + key}},[_c('validation-provider',{attrs:{"name":_vm.$t('user.attachmentType'),"rules":"required","vid":'attachmentType' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"attachmentTypeName","options":_vm.optionAttachmentTypes,"reduce":function (option) { return option.attachmentTypeId; }},model:{value:(row.attachmentTypeId),callback:function ($$v) {_vm.$set(row, "attachmentTypeId", $$v)},expression:"row.attachmentTypeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"md":"6","order":"1","order-md":"2"}},[_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deleteRow(key)}}},[_vm._v(" "+_vm._s(_vm.$t('general.btnDelete'))+" ")])],1),_c('b-col',{attrs:{"md":"6","order":"3"}},[_c('b-form-group',{attrs:{"label":row.attachmentFilePath ? _vm.$t('user.attachmentFile') : _vm.$t('user.attachmentFile') + '*'}},[(row.attachmentFilePath)?_c('thumbnail-file',{attrs:{"src":row.attachmentFilePath}}):_vm._e(),_c('validation-provider',{attrs:{"name":_vm.$t('user.attachmentFile'),"rules":row.attachmentFilePath ? '' : 'required',"vid":'attachmentFile' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"accept":".jpg, .gif, .pdf, .png","state":errors.length > 0 ? false : null},model:{value:(row.attachmentFile),callback:function ($$v) {_vm.$set(row, "attachmentFile", $$v)},expression:"row.attachmentFile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6","order":"4","align-self":"end"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user.attachmentDesc')}},[_c('b-form-input',{model:{value:(row.attachmentDescription),callback:function ($$v) {_vm.$set(row, "attachmentDescription", $$v)},expression:"row.attachmentDescription"}})],1)],1)],1)})],2),_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"success","disabled":_vm.isBtnDisabled}},[(_vm.isBtnDisabled)?_c('b-spinner',{attrs:{"small":""}}):_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t('general.btnSubmit'))+" ")],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }