<template>
  <div>
    <b-overlay :show="isShowLoading">
      <validation-observer ref="validateForm" #default="{invalid}">
        <b-form @submit.prevent="saveData">

          <b-card :title="$t('user.personalInformation')">
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('user.idCard') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.idCard')" rules="required">
                    <b-form-input v-model="userIdCard" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.prefix') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.prefix')" rules="required">
                    <v-select v-model="prefixId" label="prefixName"
                              :options="optionPrefixes" :reduce="option => option.prefixId"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.firstName') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.firstName')" rules="required">
                    <b-form-input v-model="userFirstName" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.lastName') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.lastName')" rules="required">
                    <b-form-input v-model="userLastName" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.position') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.position')" rules="required">
                    <v-select v-model="positionId" label="positionName"
                              :options="optionPositions" :reduce="option => option.positionId"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.tel') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.tel')" rules="required|integer">
                    <b-form-input v-model="userTel" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="false">
                <b-form-group :label="$t('user.bloodType')">
                  <v-select v-model="userBloodType" label="bloodName"
                            :options="optionBloodTypes" :reduce="option => option.bloodId"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="false">
                <b-form-group :label="$t('user.congenitalDisease')">
                  <b-form-input v-model="userCongenitalDisease"/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.imageProfile') + showAsterisk">
                  <thumbnail-file :src="userImagePath" v-if="userImagePath"/>
                  <validation-provider #default="{ errors }" :name="$t('user.imageProfile')" :rules="id ? '' : 'required'">
                    <b-form-file v-model="userImage" accept=".jpg, .png" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" align-self="end">
                <b-form-group :label="$t('user.email') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.email')" rules="required">
                    <b-form-input v-model="userEmail" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>


          </b-card>

          <b-card :title="$t('user.emergencyContact')">
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('user.emergencyName') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.emergencyName')" rules="required">
                    <b-form-input v-model="userEmergencyName" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('user.emergencyTel') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('user.emergencyTel')" rules="required">
                    <b-form-input v-model="userEmergencyTel" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card :title="$t('user.attachments')">
            <b-col cols="12" class="text-right">
              <b-button variant="primary" @click="addRow" class="mb-2">{{ $t('user.btnAddAttachment') }}</b-button>
            </b-col>

            <b-row v-for="(row, key) in userAttachmentLists" :key="key" align-v="center" class="attachment-row">
              <b-col md="6" order="2" order-md="1">
                <b-form-group :label="$t('user.attachmentType') + '*'" :vid="'attachmentType' + key">
                  <validation-provider #default="{ errors }" :name="$t('user.attachmentType')" rules="required" :vid="'attachmentType' + key">
                    <v-select v-model="row.attachmentTypeId" label="attachmentTypeName"
                              :options="optionAttachmentTypes" :reduce="option => option.attachmentTypeId"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" order="1" order-md="2" class="text-right">
                <b-button variant="danger" size="sm" @click="deleteRow(key)">
                  {{ $t('general.btnDelete') }}
                </b-button>
              </b-col>

              <b-col md="6" order="3">
                <b-form-group :label="row.attachmentFilePath ? $t('user.attachmentFile') : $t('user.attachmentFile') + '*'">
                  <thumbnail-file :src="row.attachmentFilePath" v-if="row.attachmentFilePath"/>
                  <validation-provider #default="{ errors }" :name="$t('user.attachmentFile')" :rules="row.attachmentFilePath ? '' : 'required'" :vid="'attachmentFile' + key">
                    <b-form-file v-model="row.attachmentFile" accept=".jpg, .gif, .pdf, .png" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" order="4" align-self="end">
                <b-form-group :label="$t('user.attachmentDesc')">
                  <b-form-input v-model="row.attachmentDescription"/>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-row>
            <b-col class="text-center">
              <b-button type="submit" variant="success" class="my-1" :disabled="isBtnDisabled">
                <b-spinner small v-if="isBtnDisabled"/>
                <feather-icon icon="SaveIcon" v-else/>
                {{ $t('general.btnSubmit') }}
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>

    </b-overlay>
  </div>

</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BFormCheckbox, BFormFile, BButton, BOverlay} from 'bootstrap-vue'
import {required, min, confirmed, integer, exact_length} from '@validations'
import tableMixins from "@/mixins/tableMixins"
import masterMixins from "@/mixins/masterMixins"
import useJwt from '@/auth/jwt/useJwt'
import {UserService, UserAttachmentService, FileStorageService, ProfileService} from "@/services"

const userService = new UserService()
const userAttachmentService = new UserAttachmentService()
const fileStorageService = new FileStorageService()
const profileService = new ProfileService()

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormFile,
    BButton,
    BSpinner,
    BOverlay,
  },
  mixins: [tableMixins, masterMixins],
  async created() {
    this.id = this.userData.userId

    await this.getData()
    await this.getAttachment()

    let [listCompany, listPrefixes, listPositions, listBloodTypes, listAttachmentType] = await Promise.all([
      this.listCompany(),
      this.listPrefix(),
      this.listPosition(),
      this.listBloodType(),
      this.listAttachmentType(),
    ])

    this.optionCompanies = listCompany
    this.optionPrefixes = listPrefixes
    this.optionPositions = listPositions
    this.optionBloodTypes = listBloodTypes
    this.optionAttachmentTypes = listAttachmentType

    this.isShowLoading = false
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    userData: JSON.parse(localStorage.getItem('userData')),
    departmentName: '',

    companyId: '',
    prefixId: '',
    userFirstName: '',
    userLastName: '',
    userIdCard: '',
    positionId: '',
    userBloodType: '',
    userCongenitalDisease: '',
    userTel: '',
    userEmail: '',
    userPassword: '',
    userRePassword: '',
    userGroupId: '',
    userCategory: '',
    userEmergencyName: '',
    userEmergencyTel: '',
    userImage: [],
    userImagePath: '',


    userAttachmentLists: [],
    optionCompanies: [],
    optionPrefixes: [],
    optionPositions: [],
    optionBloodTypes: [],
    optionAttachmentTypes: [],
    option: [
      {
        prefixId: 1,
        prefixName: 'Mr.'
      }, {
        prefixId: 2,
        prefixName: 'Ms.'
      }, {
        prefixId: 3,
        prefixName: 'Miss.'
      },
    ],
  }),
  computed: {
    showAsterisk() {
      return this.id ? '' : '*'
    },
  },
  methods: {
    async getData() {
      let queryResult = await userService.getData(this.userData.userId)
      if (queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data
        this.prefixId = result.prefixId
        this.userFirstName = result.userFirstName
        this.userLastName = result.userLastName
        this.userIdCard = result.userIdCard
        this.positionId = result.positionId
        // this.userBloodType = result.userBloodType
        // this.userCongenitalDisease = result.userCongenitalDisease
        this.userTel = result.userTel
        this.userEmail = result.userEmail
        this.userCategory = result.userCategory
        this.userEmergencyName = result.userEmergencyName
        this.userEmergencyTel = result.userEmergencyTel
        this.userImagePath = result.userImage
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        await this.$router.push({name: 'home'})
      }
    },
    async saveData() {
      this.$refs.validateForm.validate()

      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          this.isBtnDisabled = true
          let uploadResult = {fileId: null}
          if (Object.entries(this.userImage).length > 0) {
            uploadResult = await fileStorageService.upload(this.userImage)
            // {isSuccess: true, message: 'Success', fileId: 8}
            if (!uploadResult.isSuccess) {
              this.$store.commit('main/setToastError', {message: uploadResult.message})
              this.isBtnDisabled = false
              return false
            }
          }

          let actionData = {
            fileId: uploadResult.fileId,
            prefixId: this.prefixId,
            userFirstName: this.userFirstName,
            userLastName: this.userLastName,
            userIdCard: this.userIdCard,
            positionId: this.positionId,
            // userBloodType: this.userBloodType,
            // userCongenitalDisease: this.userCongenitalDisease,
            userTel: this.userTel,
            userEmail: this.userEmail,
            userEmergencyName: this.userEmergencyName,
            userEmergencyTel: this.userEmergencyTel,
          }

          let saveResult = await profileService.changeProfile(actionData)
          await this.saveAttachment(this.id)

          if (saveResult.isSuccess) {
            useJwt.refreshToken()
                .then(response => {
                  const {result} = response.data
                  const {userData, accessToken, refreshToken} = result

                  useJwt.setToken(accessToken)
                  useJwt.setRefreshToken(refreshToken)
                  delete userData.scope
                  localStorage.setItem('userData', JSON.stringify(userData))
                  this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
                  this.isBtnDisabled = false
                  setTimeout(() => {
                    window.location.href='/'
                  }, 300)
                })
          } else {
            this.isBtnDisabled = false
            this.$store.commit('main/setToastError', saveResult.data)
          }
        }
      })
    },
    addRow() {
      this.userAttachmentLists.push({
        attachmentId: '',
        attachmentDescription: '',
        attachmentTypeId: null,
        attachmentFile: [],
        attachmentFilePath: '',
        attachmentFileRealName: '',
        attachmentNum: this.userAttachmentLists.length + 1,
      })
    },
    deleteRow(row) {
      this.userAttachmentLists.splice(row, 1)
      this.userAttachmentLists = this.userAttachmentLists.map((data, key) => ({...data, attachmentNum: key + 1}))
    },
    async getAttachment() {
      let results = []
      let queryResult = await userAttachmentService.listData({userId: this.id})
      if (queryResult.isSuccess) {
        queryResult.data.results.map((result, key) => {
          results.push({
            attachmentId: result.userAttachmentId,
            attachmentDescription: result.userAttachmentDescription,
            attachmentTypeId: result.userAttachmentTypeId,
            attachmentFile: [],
            attachmentFilePath: result.userAttachmentImage,
            attachmentFileRealName: result.userAttachmentImageRealName,
            attachmentNum: key + 1,
          })
        })

        this.userAttachmentLists = results
      }
    },
    async saveAttachment(userId) {
      let attachmentLists = []
      await Promise.all(
          this.userAttachmentLists.map(async data => {
            let row = {
              attachmentId: data.attachmentId,
              attachmentDescription: data.attachmentDescription,
              attachmentTypeId: data.attachmentTypeId,
              attachmentNum: data.attachmentNum,
            }
            if (Object.entries(data.attachmentFile).length > 0) {
              let uploadResult = await fileStorageService.upload(data.attachmentFile)
              row.attachmentFileId = uploadResult.fileId
            }
            attachmentLists.push(row)
          })
      )

      let actionData = {
        userAttachmentLists: attachmentLists,
        userId: userId
      }

      await userAttachmentService.saveData(actionData)

    }
  }
}
</script>

<style lang="scss">
.attachment-row {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 25px;
  padding-bottom: 10px;
}
</style>
